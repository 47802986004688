export const DOCS = [
  {
    title: 'Podlove Web Player',
    items: [
      {
        copy: 'Information about the integration into your site, the extension possibilities of the Podlove Web Player or the use in WordPress.',
        ctaLabel: 'Documentation',
        ctaPath: 'https://docs.podlove.org/podlove-web-player/',
        icon: '../images/icons/paged-feed--white.png',
        title: 'Documentation',
      },
      {
        ctaLabel: 'Visit the Podlove Community',
        ctaPath: 'https://community.podlove.org/c/podlove-web-player/7',
        copy: 'Problems with the installation, configuration or use of the Podlove Web Player? Get in touch with us and get help from our community!',
        icon: '../images/icons/community--white.png',
        title: 'Web Player Forum',
      },
      {
        ctaLabel: 'Web Player on Github',
        ctaPath: 'https://github.com/podlove/podlove-web-player',
        copy: 'Do you want to take a look into the machinery or help us with development? Then check out the repository.',
        icon: '../images/icons/github--white.png',
        title: 'Github Repository',
      },
    ],
  },
  {
    title: 'Podlove Publisher',
    items: [
      {
        copy: 'Information about the integration into your site, the extension possibilities of the Podlove Publisher or the use in WordPress.',
        ctaLabel: 'Documentation',
        ctaPath: 'https://docs.podlove.org/podlove-publisher',
        icon: '../images/icons/paged-feed--white.png',
        title: 'Documentation',
      },
      {
        ctaLabel: 'Visit the Podlove Community',
        ctaPath: 'https://community.podlove.org/c/podlove-publisher/6',
        copy: 'Problems with the installation, configuration or use of the Podlove Publisher? Get in touch with us and get help from our community!',
        icon: '../images/icons/community--white.png',
        title: 'Publisher Forum',
      },
      {
        ctaLabel: 'Web Player on Github',
        ctaPath: 'https://github.com/podlove/podlove-publisher',
        copy: 'Do you want to take a look into the machinery or help us with development? Then check out the repository.',
        icon: '../images/icons/github--white.png',
        title: 'Github Repository',
      },
    ],
  },
  {
    title: 'Podlove Subscribe Button',
    items: [
      {
        copy: 'The Web Player WordPress plugin offers an integrated Subscribe Button for easy subscription to the podcast, which supports various podcast clients.',
        ctaLabel: 'Documentation',
        ctaPath: 'https://docs.podlove.org/podlove-web-player/wordpress-plugin/subscribe-button/',
        icon: '../images/icons/paged-feed--white.png',
        title: 'Activate the Subscribe Button within the Podlove Web Player',
      },
      {
        copy: 'This plugin allows easy inclusion of the Podlove Subscribe Button. Put it in your sidebar with a simple widget or include the button in pages and/or posts with a simple shortcode.',
        ctaLabel: 'Visit WordPress.com',
        ctaPath: 'https://wordpress.org/plugins/podlove-subscribe-button/',
        icon: '../images/icons/wordpress--white.png',
        title: 'Subscribe Button WordPress Plugin',
      },
      {
        ctaLabel: 'Subscribe Button on Github',
        ctaPath: 'https://github.com/podlove/podlove-subscribe-button',
        copy: 'Do you want to take a look into the machinery or help us with development? Then check out the repository.',
        icon: '../images/icons/github--white.png',
        title: 'Github Repository',
      },
    ]
  }
]
