import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Headline from '../elements/headline'

import ArticleHeader from '../elements/articleHeader'
import InfoCard from '../elements/infoCard'
import { getRandomColorName } from '../helpers/colors'
import { DOCS } from '../content/docs'

const DocsPage = () => (
  <Layout
    subHeaderNavTitle={'Podlove Publisher'}
  >
    <Seo title="Docs" />
    <section
      className="
        bg-blue-100
        flex
        flex-col
        items-center
        justify-center
        mt-16
        px-16
        py-48
        text-center
        w-full
      "
    >
      <Headline level={1}>Documentation</Headline>
      <Headline level={3}>Looking for help or answers? Try taking a look at our project related documantation, community forums, or the Github repositories and issues.</Headline>
    </section>
    { DOCS.map((doc, index) => 
      <div
        key={index}
        id="support"
        className="
          flex
          flex-col
          flex-wrap
          font-sans
          items-center
          justify-center
          px-12
          py-20
          w-screen
          space-y-8
          text-blue-800
          text-center
          tracking-wide
        "
      >
        <ArticleHeader
          title={doc.title}
        />
        <div
          className="
            md:flex
            items-baseline
            md:px-12
            md:space-x-4
            space-y-8
            md:space-y-4
            max-w-screen-xl
          "
        >
          { doc.items.map(item =>
            <InfoCard
              color={getRandomColorName()}
              copy={item.copy}
              ctaLabel={item.ctaLabel}
              ctaPath={item.ctaPath}
              icon={item.icon}
              key={JSON.stringify(item)}
              title={item.title}
            />
          )}
        </div>
      </div>  
    )}
  </Layout>
)

export default DocsPage
